import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { destinations } from "../../constants/destinations";
import { fonts } from "../../styles/theme";
import { useLocation } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { isAndroid, isIOS } from "react-device-detect";
import { Helmet } from "react-helmet";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  body: {
    fontFamily: fonts.primary,
    color: theme.palette.primary.main,
  },
  background: {
    width: "100vw",
    height: "100vh",
    background: ({ destination }) =>
      `url("/img/bg-${destination}.jpg") no-repeat center center fixed`,
    backgroundSize: "cover",
  },
  container: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.8rem",
    },
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: 0,
    padding: 0,
    fontFamily: fonts.secondary,
    letterSpacing: 0,
    lineHeight: 1.2,
  },
  claim: {
    fontFamily: fonts.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    color: "#3f3a68",
    margin: 0,
    padding: 0,
    fontWeight: theme.fontWeightLight,
    letterSpacing: 1,
    lineHeight: 1,
  },
  phoneImg: {
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
  },
  contentContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 80,
    },
  },
  contentPicBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: 400,
    },
    [theme.breakpoints.up("sm")]: {
      height: 500,
    },
  },
  contentTextBox: {
    padding: theme.spacing(3),
    borderRadius: 10,
    backgroundColor: "rgba(255,255,255,0.8)",
    height: "100%",
  },

  footer: {
    backgroundColor: theme.palette.primary.dark,
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "100%",
    maxHeight: 28,
    color: "white",
    fontSize: "0.7rem",
    padding: 0,

    // backgroundColor: "#f2f2f2"
  },
  footerLink: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    paddingRight: theme.spacing(2),
    color: "white",
    fontSize: "0.7rem",
  },
  quizText: {
    fontSize: "0.6em",
  },
}));

const destinationRegex = /(?:^\/)(\w+)(?:\W|$)/;

const Home = () => {
  const location = useLocation();
  console.log(location);
  const matches = destinationRegex.exec(location.pathname);
  const query = queryString.parse(location.search);
  console.log("MATCHES");
  console.log(matches);
  const destinationRequested = matches?.[1]?.toLowerCase() || "home";

  let destination = Object.keys(destinations).find(
    (key) => key === destinationRequested
  );
  if (!destination) {
    window.location = "https://actyvyst.de";
  } else if (isAndroid) {
    window.location = `https://${destination}.actyvyst.de`;
  }

  const theme = useTheme();
  const classes = useStyles({ destination });
  const config = destinations[destination];

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMidSize = useMediaQuery(theme.breakpoints.down("md"));

  if (!destination) return <div></div>;
  return (
    <div className={classes.background}>
      {/* <Typography>Media match: {JSON.stringify({ isMobile, isAndroid, isIOS })}</Typography> */}
      <Helmet>
        <title>actyvyst {config.name} - Jetzt herunterladen!</title>
        <meta
          property="og:title"
          content={`actyvyst ${config.name} - Jetzt herunterladen`}
        />
        <meta
          property="og:description"
          content="Dein digitaler Freizeitguide"
        />
        <meta
          property="og:image:secure_url"
          content={`${process.env.PUBLIC_URL}/img/preview-${destination}.jpg`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/${destination}`}
        />
      </Helmet>
      <Container className={classes.container}>
        <div style={{ textAlign: "center" }}>
          <div style={{ display: "inline-block", textAlign: "left" }}>
            <Typography>
              <span className={classes.logo}>actyvyst </span>
              <span
                className={classes.logo}
                style={{ color: theme.palette.secondary.main }}
              >
                {config.name}
              </span>
              <br />
              <span className={classes.claim}>
                Dein digitaler Freizeitguide.
              </span>
            </Typography>
          </div>
        </div>

        <Grid container className={classes.contentContainer}>
          {!isMidSize ? (
            <Grid item xs={12} md={3} className={classes.contentPicBox}>
              <img
                className={classes.phoneImg}
                src={`/img/screenshot-${destination}.png`}
                alt="iPhoneXScreenshot"
              />
            </Grid>
          ) : undefined}

          <Grid
            item
            xs={12}
            md={isMidSize ? 12 : 9}
            className={classes.contentTextBox}
            style={{ textAlign: isMobile ? "center" : "left" }}
          >
            <Typography variant="h2" color="secondary">
              {config.descriptionHead}
            </Typography>
            <ReactMarkdown className={classes.body}>
              {isMobile
                ? config.descriptionMarkdownMobile
                : config.descriptionMarkdown}
            </ReactMarkdown>
            <Grid container style={{ marginBottom: 20 }}>
              {isIOS || isAndroid ? (
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  md={12}
                  style={{
                    textAlign: "center",
                    height: 80,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <a
                    href={
                      isIOS
                        ? config.appstoreLink
                        : isAndroid
                        ? config.playstoreLink
                        : config.appstoreLink
                    }
                    rel="noreferrer"
                    target="_blank"
                    style={{ justifyContent: "center", width: "100%" }}
                  >
                    <img
                      src={
                        isIOS
                          ? "/img/apple-badge.png"
                          : isAndroid
                          ? "/img/google-play-badge.png"
                          : "/img/apple-badge.png"
                      }
                      alt={
                        isIOS
                          ? "Jetzt im App Store laden"
                          : isAndroid
                          ? "Jetzt im Google Play Store laden"
                          : "Jetzt im App Store laden"
                      }
                      style={{
                        width: "80%",
                        maxWidth: 280,
                      }}
                    />
                  </a>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ height: 60, marginTop: 10, marginBottom: 10 }}
                  >
                    <a
                      href={config.appstoreLink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={"/img/apple-badge.png"}
                        alt="Jetzt im App Store laden"
                        style={{
                          height: "100%",
                        }}
                      />
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ height: 60, marginTop: 10, marginBottom: 10 }}
                  >
                    <a
                      href={config.playstoreLink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={"/img/google-play-badge.png"}
                        alt="Jetzt im Google Play Store laden"
                        style={{
                          height: "100%",
                        }}
                      />
                    </a>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            {query.quiz ? (
              <Grid container style={{ marginBottom: 20 }}>
                <ReactMarkdown className={classes.quizText}>
                  {config.quizMarkdown}
                </ReactMarkdown>
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
      </Container>
      <div
        className={classes.footer}
        style={{
          display: "flex",
          margin: 0,
          padding: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className={classes.footerLink}>
          © 2019{" "}
          <a
            href="https://actyvyst.de"
            rel="noreferrer"
            target="_blank"
            style={{ color: "white" }}
          >
            actyvyst GmbH
          </a>
        </span>
        <a
          href="https://actyvyst.de/datenschutz"
          className={classes.footerLink}
        >
          Datenschutz
        </a>
        <a href="https://actyvyst.de/impressum" className={classes.footerLink}>
          Impressum
        </a>
      </div>
    </div>
  );
};

export default Home;
