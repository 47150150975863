import { createMuiTheme } from "@material-ui/core/styles";

export const colors = {
    actyDarkBlue: "#14143D",
    actyLightBlue: "#4196F6",
    actyRed: "#CB016F",
    actyGreen: "#1F990E",
    actyGray: "#222222",
    actyBackground: "#fdfdff"
};
export const fonts = {
    primary: "'Open Sans', sans-serif",
    secondary: "'Days One', sans-serif"
};
// const fonts = {
//   mainTitle: {
//     fontFamily: "'Days One', sans-serif",
//     fontSize: 20,
//   },
//   headline: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 500,
//     fontSize: 20,
//     color: colors.actyDarkBlue,
//   },
//   subHeadline: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 700,
//     fontSize: 14,
//     color: colors.actyLightBlue,
//   },
//   body: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 400,
//     fontSize: 12,
//     color: colors.actyDarkBlue,
//   }
// };
const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.actyDarkBlue
        },
        secondary: {
            main: colors.actyLightBlue,
            background: colors.actyBackground
        },
        error: {
            main: colors.actyRed
        },
        text: {
            primary: colors.actyDarkBlue,
            secondary: colors.actyLightBlue
        }
    },
    props: {
        MuiTypography: {
            gutterBottom: true
        },
        MuiButton: {
            variant: "contained",
            color: "secondary"
        },
        MuiCard: {
            elevation: 6
        },

        MuiToolbar: {
            variant: "dense"
        }
        // MuiContainer: {

        // }
    },
    overrides: {
        MuiButton: {
            label: {
                fontWeight: 600
            },
            containedPrimary: {
                backgroundColor: "#2d2d8b",
                "&:hover": {
                    backgroundColor: "#14143d"
                }
            },
            outlinedPrimary: {
                boxShadow: "none",
                borderColor: colors.actyLightBlue,
                backgroundColor: "none",
                color: colors.actyLightBlue,
                textTransform: "none",
                "&:active": {
                    borderColor: colors.actyLightBlue,
                    boxShadow: "none"
                },
                "&:focus": {
                    borderColor: colors.actyLightBlue,
                    boxShadow: "none"
                }
            }
        },
        MuiPaper: {
            root: {
                padding: 10
            },
            rounded: {
                borderRadius: 10
            }
        },
        MuiIconButton: {
            root: {
                paddingTop: 6,
                marginLeft: 0,
                color: colors.actyLightBlue
            }
        },
        // MuiContainer: {
        //     root: {
        //         marginTop: 70
        //     }
        // },
        MuiListItemIcon: {
            root: {
                minWidth: 20,
                marginTop: 8
            }
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: fonts.primary,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
            fontSize: "3.8rem",
            fontWeight: 700,
            fontFamily: fonts.secondary,
            paddingLeft: 6,
            letterSpacing: 0,
        },
        h2: {
            fontFamily: fonts.primary,
            fontSize: "1.5rem",
            fontWeight: 700,
            maxLines: 1,
            lineClamp: 1
        },

        h3: {
            fontSize: "1.4rem",
            fontWeight: 600
        },
        h4: {
            fontSize: "1.2rem",
            fontWeight: 600
        },
        h5: {
            fontSize: "1.0rem",
            fontWeight: 600
        },
        h6: {
            fontSize: "0.9rem",
            fontWeight: 600
        },
        subtitle1: {
            fontSize: "0.9rem",
            color: colors.actyLightBlue,
            fontWeight: 600,
            lineHeight: "100%",
            lineClamp: 2
        },
        subtitle2: {
            fontSize: "1rem",
            color: colors.actyLightBlue,
            fontWeight: 600
        },

        caption: {
            fontSize: "0.6rem"
        }
    }
});

export default theme;
