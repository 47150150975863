export const destinations = {
  ruegen: {
    name: "Rügen",
    bgImg: "bg-ruegen.jpg",
    appstoreLink:
      "https://apps.apple.com/us/app/actyvyst-r%C3%BCgen/id1550101140",
    playstoreLink:
      "https://play.google.com/store/apps/details?id=com.actyvyst.ruegen",
    descriptionHead: "Moin, schon was vor?",
    descriptionMarkdownMobile: `
Die **actyvyst Rügen** App bietet dir einen tollen
Überblick über alle Aktivitäten, die du auf Rügen unternehmen
kannst.

Jetzt herunterladen und ausprobieren:         
`,
    descriptionMarkdown: `
**actyvyst Rügen** bietet dir einen umfassenden und kostenlosen
Überblick über fast alle Aktivitäten, die du auf Rügen unternehmen
kannst: Wassersport, Naturerlebnisse, kulturelle Highlights,
Wellness-Oasen, exklusive Boutiquen und die vielfältige Gastronomie
machen hier jeden Urlaub einzigartig.

Detaillierte Informationen zu Lage und Erreichbarkeit der Anbieter
erleichtern die Kontaktaufnahme.

Viele Aktivitäten sind direkt online buchbar - Du erhältst ein digitales Ticket,
das Du einfach beim Veranstalter vorzeigst.

Lade dir die App jetzt im App Store oder im Google Play Store
herunter: 
        `,
    quizMarkdown: `
Und hier die Antworten auf unsere **Quizfragen**:  
  
**Wo** gab Jimi Hendrix sein erstes Konzert?  
  
**c) Fehmarn**  
    
**Wie** wird die Rügensche BäderBahn im Volksmund genannt?  
  
**c) Rasender Roland**  
    
**Wie viele** Kraniche machen von August bis Oktober Rast auf Rügen?  
  
**b) Bis 30.000**
`,
  },
  sylt: {
    name: "Sylt",
    bgImg: "bg-sylt.jpg",
    appstoreLink:
      "https://apps.apple.com/us/app/actyvyst-sylt/id1465082431?l=de&ls=1",
    playstoreLink: "https://sylt.actyvyst.de",
    descriptionHead: "Moin, schon was vor?",
    descriptionMarkdownMobile: `
Die **actyvyst Sylt** App bietet dir einen tollen
Überblick über alle Aktivitäten, die du auf Sylt unternehmen
kannst.

Jetzt herunterladen und ausprobieren:         
`,
    descriptionMarkdown: `
**actyvyst Sylt** bietet dir einen umfassenden und kostenlosen
Überblick über fast alle Aktivitäten, die du auf Sylt unternehmen
kannst: Wassersport, Naturerlebnisse, kulturelle Highlights,
Wellness-Oasen, exklusive Boutiquen und die vielfältige Gastronomie
machen hier jeden Urlaub einzigartig.

Detaillierte Informationen zu Lage und Erreichbarkeit der Anbieter
erleichtern die Kontaktaufnahme.

Viele Aktivitäten sind direkt online buchbar - Du erhältst ein digitales Ticket,
das Du einfach beim Veranstalter vorzeigst.

Lade dir die App jetzt im App Store oder im Google Play Store
herunter: 
        `,
    quizMarkdown: `
Und hier die Antworten auf unsere **Quizfragen**:  
  
**Wo** gab Jimi Hendrix sein erstes Konzert?  
  
**c) Fehmarn**  
  
Von **welcher** Stadt gibt es keine Direktflugverbindung nach Sylt?  
  
**b) Köln/Bonn**  
  
**Wie** heißt kein Ort auf Sylt?  
  
**c) Kleinbonum**
        `,
  },
  mallorca: {
    name: "Mallorca",
    bgImg: "bg-sylt.jpg",
    appstoreLink: "https://itunes.apple.com/de/app/actyvyst/id1565782272",
    playstoreLink:
      "https://play.google.com/store/apps/details?id=com.actyvyst.mallorca",
    descriptionHead: "Hola, schon was vor?",
    descriptionMarkdownMobile: `
Die **actyvyst Mallorca** App bietet dir einen tollen
Überblick über alle Aktivitäten, die du auf Mallorca unternehmen
kannst.

Jetzt herunterladen und ausprobieren:         
`,
    descriptionMarkdown: `
**actyvyst Mallorca** bietet dir einen umfassenden und kostenlosen
Überblick über fast alle Aktivitäten, die du auf Mallorca unternehmen
kannst: Wassersport, Naturerlebnisse, kulturelle Highlights,
Wellness-Oasen, exklusive Boutiquen und die vielfältige Gastronomie
machen hier jeden Urlaub einzigartig.

Detaillierte Informationen zu Lage und Erreichbarkeit der Anbieter
erleichtern die Kontaktaufnahme.

Viele Aktivitäten sind direkt online buchbar - Du erhältst ein digitales Ticket,
das Du einfach beim Veranstalter vorzeigst.

Lade dir die App jetzt im App Store oder im Google Play Store
herunter: 
      `,
    quizMarkdown: `
Und hier die Antworten auf unsere **Quizfragen**:  

**Wo** gab Jimi Hendrix sein erstes Konzert?  

**c) Fehmarn**  

Von **welcher** Stadt gibt es keine Direktflugverbindung nach Sylt?  

**b) Köln/Bonn**  

**Wie** heißt kein Ort auf Sylt?  

**c) Kleinbonum**
      `,
  },
};
