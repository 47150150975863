import { useEffect } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import Home from './screens/Home';
import { ThemeProvider } from "@material-ui/styles";
import theme from "./styles/theme";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import TrackingWrapper from './modules/TrackingWrapper';

function App() {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <TrackingWrapper>
            <Route path="/"><Home /></Route>
          </TrackingWrapper>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
