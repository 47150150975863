import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-WQM78GB'
}
TagManager.initialize(tagManagerArgs)

const TrackingWrapper = (props) => {
  const location = useLocation();
  useEffect(() => {
    // console.log('----------LOCATION-CHANGE----------');
    // console.log({ location: location.pathname, path: window.location.hostname + window.location.pathname + window.location.search });
    // console.log('--------END-LOCATION-CHANGE--------');
    window.dataLayer.push({
      event: 'pageview'
    });
  }, [location])

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default TrackingWrapper;

